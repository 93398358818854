<template>
	<div v-loading.fullscreen.lock="fullscreenLoading">
		<div class="box">
			<Title title="发票列表" />
			<div class="ct_box">
				<div class="search_status_box flex">
					<div class="search_box flex">
						<order_time @get_time="get_time" />
					</div>
					<orderStatus width="300" :order_status="status" :status_list="status_list"
						@status_tab="status_tab" />
					<!-- <ul class="status_ul flex">
						<li :class="status.title == item.title ? 'active' : ''" v-for="(item, index) in status_list"
							:key="index" @click="statusShow(item)">
							{{ item.title }}
						</li>
					</ul> -->
				</div>
				<el-table :data="tableData" style="width: 100%;">
					<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" prop="date" align="center" label="类型" width="350">
						<template slot-scope="scope">
							电子发票(1%税点)
						</template>
					</el-table-column>
					<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" prop="header" align="center" label="抬头" width="350">
					</el-table-column>
					<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" prop="amount" align="center" label="开票申请" width="320">
					</el-table-column>
					<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" prop="date" align="center" label="状态" width="300">
						<template slot-scope="scope">
							<div
								:style="{ color: scope.row.status == 0 ? '#333' : scope.row.status == 1 ? '#FF8400' : scope.row.status == 2 ? '#00B052' : '#FF0000' }">
								{{ scope.row.status == 0 ? '待审核' : scope.row.status == 1 ? '开票中' : scope.row.status == 2
		? '已完成' :
		'被拒单' }}
							</div>
						</template>
					</el-table-column>
					<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" prop="ctime" align="center" label="申请时间" width="250">
					</el-table-column>
				</el-table>
				<!-- 分页器 -->
				<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
			</div>
		</div>
	</div>
</template>

<script>
import Title from '@/components/UserInfoTitle'
import order_time from '@/components/order_time'
import orderStatus from '@/components/orderStatus'
export default {
	components: {
		Title,
		order_time,
		orderStatus
	},
	mounted() {
		this.get_list()
	},
	data() {
		return {
			time: [],
			status_list: [
				{
					id: '',
					title: '全部',
				},
				{
					id: 0,
					title: '待审核',
				}, {
					id: 1,
					title: '开票中'
				}, {
					id: 2,
					title: '已完成'
				}, {
					id: 3,
					title: '被拒单'
				}],
			status: '',
			tableData: [],
			page: 1,
			total_page: 0, //分页总页数
			count: 0, //总条数
			fullscreenLoading: false, //loding

		}
	},
	methods: {
		pageChange(pageVal) {
			this.page = pageVal
			this.get_list()
		},
		get_time(time) {
			this.time = time || []
			this.pageChange(1)
		},
		status_tab(id) {
			console.log(id);
			this.status = id
			this.pageChange(1)
		},
		get_list() {
			this.fullscreenLoading = true
			let data = {}
			if (this.status != '') {
				data.status = this.status
			}
			if (this.time.length) {
				data.start_time = this.time[0] / 1000
				data.end_tiem = this.time[1] / 1000
			}
			this.curlPost('/api/users/guanggao_invoice', data).then(res => {
				if (res.data.code) {
					this.tableData = res.data.data.list
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
				}
				this.fullscreenLoading = false
			})
		}
	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/Invoice';
@import '@/scss/placard_media';
</style>